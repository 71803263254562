<template>
    <main>
        
        <section class="section pt-3 demo">
            <div class="container">
                
                <div class="login text-center">
                
                    <h2><strong>Ingresar</strong></h2>
                    
                    
                    <form action="" class="form container container--xs form-login" @submit.prevent="login(form)">
                        
                        <div class="form__control text-left">
                            <label class="form__label"><strong>E-mail o Número teléfonico:</strong></label>
                            <input type="text" class="form__field" placeholder="Ingresa con tu email" v-model="form.email">
                        </div>
                        <p class="form__alert" >
                                
                        </p>
                        
                        <div class="form__control text-left">
                            <label class="form__label"><strong>Contraseña :</strong></label>
                            <input v-bind:type="[showPassword ? 'text' : 'password']" class="form__field" style="display:initial;" placeholder="Ingresa tu contraseña" v-model="form.password">
                            <span @click="showPassword = !showPassword"><i :class="[showPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill']" id="togglePassword"></i></span>
                            

                        </div>
                        <p class="form__alert">
                                
                        </p>                        
                        <p class="form__alert">
                            {{ store.state.dataMessageErrorLogin.detail }}                            
                            <template v-if="store.state.dataMessageErrorLogin.non_field_errors">
                                {{ store.state.dataMessageErrorLogin.non_field_errors[0] }}
                            </template>
                        </p>
                        
                        <div class="form__control text-center">
                        <router-link :to="{ name: 'RegisterUser' }">
                            <span class="link-club">¿Aún no estas registrado?</span>
                        </router-link>					                            
                        </div>
                        <button class="cta pt-1">Ingresar</button>
                        
                        

                    </form>
                    <div class="form__control text-center">
                        <router-link :to="{ name: 'CredentialLinkRecovery' }">
                            <span class="link-club">Recuperar contraseña</span>
                        </router-link>					                            
                    </div>
                </div>    
                <!-- hahshajjasjkkjasj -->
            </div>
        </section>
        
    </main>
</template>

<script>

import { ref } from "vue";
import API from "@/api";
import router from '@/router/index';
import store from '@/store';
import { mapActions, mapState } from "vuex";


export default {
    name: 'Index',
    components: {
      
    },
    setup(){
        const form = ref({ 
            email: "",
            password: "",            
        });        
        const showPassword = ref(false)

        const messagePost = ref({})
        const messageError = ref({errors:{}})              

        
        return {form, messagePost, messageError, ...mapActions(['login']),  store, showPassword}
    },
    methods: {
        
    }

}
</script>


<style lang="css" scoped>
form i {
    margin-left: -30px;
    cursor: pointer;
}
</style>